.module--text-image {
    position:relative;
    overflow:hidden;
    .bg--holder{
        position: absolute;
        width: 1000px;
        left: 55%;
        top: 50%;
        img{
            transform: translateY(-50%);
        }
    }
    .text{
        line-height: $grid-gutter-width;
    }
}

@include media-breakpoint-down(md) {
    .module--text-image .container .align-items-center{min-height:auto;}
    .module--text-image .bg--holder{
        padding-top:60px;
        position: relative;
        left:0;
        top:0;
        width:100%;
        transform:translate(0%);
        img{
            transform: translateY(0%);
        }
        /*        left: 0%;
                top: 0%;*/
    }
}