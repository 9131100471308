// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width:     30px !default;

// colors
$main-blue: rgba(15,58,93,100);
$light-blue: rgb(243,245,247);

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #080c0f !default;
$black:  #000 !default;

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
) !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #d4021d !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray-600,
        gray-dark: $gray-800,
        black: $black
) !default;

$theme-colors: (
        primary: $main-blue,
        secondary: $light-blue,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $white,
        dark: $gray-900,
        black: $black
) !default;
$spacer: 30px !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  60: ($spacer * 2),
  90: ($spacer * 3),
  120: ($spacer * 4),
  150: ($spacer * 5),
  180: ($spacer * 6),
  210: ($spacer * 7),
  240: ($spacer * 8),
) !default;
$border-radius:30px;
$input-btn-padding-x:30px;

$fontface--regular:	"Carnas-Regular", helvetica, arial, sans-serif;
$fontface--medium:	"Carnas-Medium", helvetica, arial, sans-serif;
$fontface--bold:	"Carnas-Bold", helvetica, arial, sans-serif;
$fontface--light:	"Carnas-Light", helvetica, arial, sans-serif;



@mixin transition($duration) {
    -webkit-transition: all $duration ease;
    -moz-transition:all $duration ease;
    -ms-transition:all $duration ease;
    -o-transition:all $duration ease;
    transition:all $duration ease;
}

@mixin shadow{
    -webkit-box-shadow: 0px 1px 14px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 1px 14px 0px rgba(0,0,0,0.3);
box-shadow: 0px 1px 14px 0px rgba(0,0,0,0.3);
}

$url: 'aqualibur';