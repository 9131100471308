.module--content-slider{
    .content{line-height: 30px;}
    .btn{min-width:210px;}
    .dotholder{text-align:center;}
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid rgba($main-blue,0.4);
    @include transition(0.4s);
}
.slick-dots li:hover,.slick-dots li.slick-active{
    border: 2px solid rgba($main-blue,1);;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 0px;
    height:0px;
    padding: 0px;
    cursor: pointer;
    color: #fff;
    border: 0;
    outline: none;
    background: #fff;
}
.slick-dots {
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}