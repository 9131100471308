/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 06.03.2018, 11:13:29
    Author     : mberndt
*/

.module--contact label{display:block}
.module--contact input, .module--contact textarea{border:none; width:100%;border-radius:0px; padding:10px; line-height: 20px; margin-bottom:30px}
.module--contact .radio label{display:inline-block}
.module--contact input.radio{width:20px;}
.module--contact p.radio{display:inline-block; width:80px;}
.module--contact label.radio{display:none;}
.module--contact input:focus, .module--contact textarea:focus{outline:$main-blue auto 1px;}
.module--contact input[type=radio]{outline: none!important;}
.module--contact p{margin-bottom: 0px; padding-bottom:0px;}
.module--contact hr{margin-bottom:0px;}