footer{
    background-color: $main-blue;
    color: $white; 
    .footer-logo{
        width:190px;
    }
    address{color:$white;}

    .sub-footer{border-top: 1px solid $white}
    .copyright{text-align: right;}

    .icon-links{text-align: right;    padding-top: 22.5px}
    .icon-link{
        float:right;
    }

    .icon-holder img{fill: $main-blue;}
    .footer-nav{text-align: right;}
    .footer-nav .inline-list li{
        padding-left: 30px;
        margin-right: 0px;
        text-transform: uppercase;
    }
}
.inline-list{padding:0px; margin:0px;}
.inline-list li{
    display: inline-block;
    margin-right: -4px;
    a{color: $white;}
}
.inline-list--bullets{
    list-style: none;
}
.inline-list--bullets li:not(:last-child):after {
    display: inline-block;
    content: "\2219";
    margin: 0 5px;
    color:$white;
}
.icon-holder {
    border-radius: 15px;
    padding: 6px;
    width: 30px;
    height: 30px;
    background: $white;
}
.icon-link{
    float:right;
}

@include media-breakpoint-down(xs) {
    footer .footer-logo{
        width:100px;
    }
    footer .icon-links {
    text-align: right;
    padding-top: 5px;
}
footer address.pl-5{padding-left:0px!important; padding-top:$grid-gutter-width;}
footer .pb-4{padding-bottom:$grid-gutter-width!important;}
}