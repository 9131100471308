.module--text .text{
    display:block;
    columns: 2;
    column-gap: 2*$grid-gutter-width;
    break-inside: avoid;
    line-height: $grid-gutter-width;
}

@include media-breakpoint-down(sm) {
    .module--text .text{
        columns: 1;
    }
}