.module.module--fullscreenVideo {
    background-color: #f6f7f9;
}
.leftImageHolder{
/*    background: url('/resources/assets/tmp/bannerLinks.png');*/
    background-size: 50vw 100%!important;
    background-position: 0% 0!important;
    background-repeat: no-repeat!important;
}
.rightImageHolder{
/*    background: url('/resources/assets/tmp/bannerRechts.png');*/
    background-size: 50vw 100%!important;
    background-position: 100% 0!important;
    background-repeat: no-repeat!important;
}
.module.module--fullscreenVideo .container-fluid{
    padding:0px;
    //min-height:100vh;
}
.module.module--fullscreenVideo .container-fluid .col{
    padding:0px;
}
.module.module--fullscreenVideo video.w-100{display:block;}
.sliderWrapper{
    display: block;
    height:100%;
}
.sliderWrapper .caption{
    position: relative;
    margin-top:-10vh;
/*    position:absolute;
    bottom: 0;*/
    padding-left:$grid-gutter-width;
    padding-right:$grid-gutter-width;
}
.sliderWrapper .caption h3, .sliderWrapper .caption p{padding-left:3%}
.sliderWrapper .caption p{padding-left:3%; padding-right:3%;}
