// Helper classes
body{margin:0px;
     font-family: $fontface--light;
     font-size:14px;
     color: $main-blue;}
.fittyseventyfive{width:75%;}
.fittyonehundred{width:100%;}
.fitty{line-height: 0.8;}
h1,h2,h3,h4,h5,h6{text-transform:uppercase;}
h1,h2,h3,h4,h5,h6,a{
    color: $main-blue;
}
h2{font-size:34px;}
h4{font-size:15.5px;}
.arrowDown{text-align: center;}
.arrowDown img{width:44px; cursor:pointer}
.shadow{
    @include shadow;
}
*:focus{outline:none;}
button:focus{outline:none;}
.theEnd{width:1px;}
/*.module-p180{padding-bottom:$grid-gutter-width*6; padding-top:$grid-gutter-width*6;}*/

/*.pt-0{padding-top:$grid-gutter-width*0}
.pt-30{padding-top:$grid-gutter-width*1}
.pt-60{padding-top:$grid-gutter-width*2}
.pt-90{padding-top:$grid-gutter-width*3}
.pt-120{padding-top:$grid-gutter-width*4}
.pt-150{padding-top:$grid-gutter-width*5}
.pt-180{padding-top:$grid-gutter-width*6}
.pt-210{padding-top:$grid-gutter-width*7}
.pt-240{padding-top:$grid-gutter-width*8}

.pb-0{padding-bottom:$grid-gutter-width*0}
.pb-30{padding-bottom:$grid-gutter-width*1}
.pb-60{padding-bottom:$grid-gutter-width*2}
.pb-90{padding-bottom:$grid-gutter-width*3}
.pb-120{padding-bottom:$grid-gutter-width*4}
.pb-150{padding-bottom:$grid-gutter-width*5}
.pb-180{padding-bottom:$grid-gutter-width*6}
.pb-210{padding-bottom:$grid-gutter-width*7}
.pb-240{padding-bottom:$grid-gutter-width*8}*/

.pl-30{padding-left:$grid-gutter-width;}
.pr-30{padding-right:$grid-gutter-width;}

/*.mt-30{margin-top:$grid-gutter-width*1}
.mt-60{margin-top:$grid-gutter-width*2}
.mt-90{margin-top:$grid-gutter-width*3}
.mt-120{margin-top:$grid-gutter-width*4}
.mt-180{margin-top:$grid-gutter-width*6}

.mb-30{margin-bottom:$grid-gutter-width*1}
.mb-60{margin-bottom:$grid-gutter-width*2}
.mb-90{margin-bottom:$grid-gutter-width*3}
.mb-120{margin-bottom:$grid-gutter-width*4}
.mb-180{margin-bottom:$grid-gutter-width*6}*/



.module--noPadding{padding:0px!important;}
.module--fullheight .container .align-items-center{min-height:100vh;}
.module--fullheight .container{flex-direction: column; display:flex;}
.bg--lightblue{background-color: $light-blue}
.bg--white{background-color: $white}
.btn{box-shadow: none!important}
/*.animationOn .os-animation, .animationFake .os-animation{opacity:0;}
.isKackbrowser.animationOn .os-animation{opacity:1;}*/
/*.isKackbrowser.animationOn .os-animation.animated{opacity:1!important; visibility: visible;}*/
/*.animationOn .os-animation.animated{opacity:1;}*/
@include media-breakpoint-down(sm) {
/*    .pt-60, .pt-90, .pt-120, .pt-150, .pt-180, .pt-210, .pt-240{padding-top:$grid-gutter-width*1}
    .pb-60, .pb-90, .pb-120, .pb-150, .pb-180, .pb-210, .pb-240{padding-bottom:$grid-gutter-width*1}
    .mt-180,.mt-120,.mt-90{margin-top:$grid-gutter-width*1}
    .mb-180,.mb-120,.mb-90{margin-bottom:$grid-gutter-width*1}*/
    .pl-30{padding-left:$grid-gutter-width/2;}
    .pr-30{padding-right:$grid-gutter-width/2;}
    .module--header-image.pt-180{padding-top:$grid-gutter-width*4}
}
.btn{cursor:pointer; min-width:180px;}

@media only screen and (max-width: 1024px){
    .featherlight .featherlight-content {
        margin-left: 0;
        margin-right: 0;
        max-height: 98%;
        padding: 30px 30px 0;
        border-bottom: 30px solid transparent;
    }
}

.slideInRight {
opacity:1!important;
}
span.btn{min-width: auto; cursor:default; width:100%}