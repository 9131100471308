header{
    background:transparent!important;
    .navbar-brand{margin:0px; padding:0px;}
    .navbar{padding:30px 0px 0px 0px;}
    .navbar .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://ww…p='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    .navbar .navbar-holder{@include transition(1s);background:transparent;}
    .navbar.scroll .navbar-holder{background: $white; @include shadow;}
    .navbar .navbar-holder .container{    display: -ms-flexbox;
                                          display: flex;
                                          -ms-flex-wrap: wrap;
                                          flex-wrap: wrap;
                                          -ms-flex-align: center;
                                          align-items: center;
                                          -ms-flex-pack: justify;
                                          justify-content: space-between;}
    .navbar-toggler{color:$main-blue; padding:0px; cursor: pointer;}
    .navbar .shoplink{
        @include transition(1s);
        opacity:0;
        width: 200px;
        img{
            height: 20px;
        }
    }
    .navbar.scroll .shoplink{ opacity:1;}
    #aqualiburheader .row{
        margin:0px;
    }
}
.navbar-toggler:focus, .navbar-toggler:hover {outline:none;}
.header-logo{width:170px}
@media (min-width: 602px){
    .navbar .navbar-holder {
        max-width: 600px;
    }
}
@media (max-width: 602px){
    .navbar .navbar-holder {
        max-width: 602px;
    }
}

@media (min-width: 782px){
    .navbar .navbar-holder {
        max-width: 780px;
    }
}
@media (min-width:722px) and (max-width: 782px){
    .navbar .navbar-holder {
        max-width: 720px;
    }
}

@media (min-width: 1022px){
    .navbar .navbar-holder {
        max-width: 1020px;
    }
}
@media (min-width:962px) and (max-width: 1022px){
    .navbar .navbar-holder {
        max-width: 960px;
    }
}

@media (min-width:1142px) and (max-width: 1232px){
    .navbar .navbar-holder{max-width: 1140px;}
}
@media (min-width: 1232px){
    .navbar .navbar-holder{max-width: 1230px;}
}


@include media-breakpoint-down(lg) {
    .modal-head-holder{
        padding: $grid-gutter-width 45px!important;
    }
}

#fullscreenMenu{
    .modal-dialog {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        transform: translate(0);
        background-color: rgba($main-blue,0.8);
    }
    .modal-header{
        border-bottom: none;
    }
    .modal-content {
        height: auto;
        height: 100%;
        border-radius: 0;
        background-color: transparent;

        .container{
            height:100%;
            .row{
                height:100%;
            }
        }
    }
    .modal-body {
        height:100%;
        overflow: scroll;
    }
    .main-nav{
        text-align: center;
        list-style-type: none;
        padding:0px;
    }
    .main-nav li{
        margin-bottom:$grid-gutter-width;
    }
    .main-nav li a{
        color:$white;
        font-size:34px;

    }
    .col.logo{text-align: right;}
    .navbar-toggler{color:$white; cursor: pointer; padding:0px;}
}




.blur section, .blur footer, .blur header{
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
    -webkit-filter: url(#blur-filter);
    filter: url(#blur-filter);
    -webkit-filter: blur(3px);
    filter: blur(3px);
}
.blur-svg {
    display: none;
}
.sub-nav{
    border-top:1px solid $white;
    max-width: 600px;
    ul{float:left; padding-top:10px;}
}
@include media-breakpoint-down(sm) {
    header .navbar .shoplink{
        width: auto;
        padding-left:20px;
        padding-right:20px;
    }


}
@include media-breakpoint-down(xs) {
    .modal-head-holder{
        padding:0px 0px!important;
    }
    .header-logo{width:100px;}
    header .navbar .shoplink.btn{
            min-width: auto;
/*        display:none!important;*/
    }
    header .navbar{padding:0px 0px 0px 0px;}
    #aqualiburheader .container.px-5{padding-left:30px!important; padding-right: 30px!important;}
    .sub-nav ul{
        padding-top:0px!important;
        float:none;
    }
    .sub-nav .icon-links{
            width: 105px;
    }
    .sub-nav .icon-link{
        padding-top:15px;
        //float:none;
    }
    #fullscreenMenu .main-nav li a{
        color:$white;
        font-size:30px;

    }
    #fullscreenMenu .main-nav li{
/*        margin-bottom:0px;*/
        margin-bottom:$grid-gutter-width/2;
    }
    .sub-nav-animation{margin-top:$grid-gutter-width!important}
    #fullscreenMenu .icon-holder {
        border-radius: 10px;
        padding: 0px 4px;
        width: 20px;
        height: 20px;
        background: #fff;
        img{width:12px;}
    }
    .modal-body .col12.align-self-start{
        padding:0px;

    }
}
.main-nav-animation, .sub-nav-animation{
    animation: animationFrames-mainNav ease-in 0.6s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames-mainNav ease-in 0.6s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames-mainNav ease-in 0.6s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames-mainNav ease-in 0.6s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames-mainNav ease-in 0.6s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}
.main-nav-animation, .sub-nav-animation{
    animation: animationFrames-mainNav ease-in 0.6s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames-mainNav ease-in 0.6s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames-mainNav ease-in 0.6s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames-mainNav ease-in 0.6s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames-mainNav ease-in 0.6s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames-mainNav{
    0% {
        opacity:0;
        transform:  scaleX(10.00) scaleY(10.00) ;
    }
    100% {
        opacity:1;
        transform:  scaleX(1.00) scaleY(1.00) ;
    }
}

@-moz-keyframes animationFrames-mainNav{
    0% {
        opacity:0;
        -moz-transform:  scaleX(10.00) scaleY(10.00) ;
    }
    100% {
        opacity:1;
        -moz-transform:  scaleX(1.00) scaleY(1.00) ;
    }
}

@-webkit-keyframes animationFrames-mainNav {
    0% {
        opacity:0;
        -webkit-transform:  scaleX(10.00) scaleY(10.00) ;
    }
    100% {
        opacity:1;
        -webkit-transform:  scaleX(1.00) scaleY(1.00) ;
    }
}

@-o-keyframes animationFrames-mainNav {
    0% {
        opacity:0;
        -o-transform:  scaleX(10.00) scaleY(10.00) ;
    }
    100% {
        opacity:1;
        -o-transform:  scaleX(1.00) scaleY(1.00) ;
    }
}

@-ms-keyframes animationFrames-mainNav {
    0% {
        opacity:0;
        -ms-transform:  scaleX(10.00) scaleY(10.00) ;
    }
    100% {
        opacity:1;
        -ms-transform:  scaleX(1.00) scaleY(1.00) ;
    }
}

.sub-nav-animation{
    animation: animationFrames-subNav ease-in 0.8s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames-subNav ease-in 0.8s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames-subNav ease-in 0.8s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames-subNav ease-in 0.8s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames-subNav ease-in 0.8s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames-subNav{
    0% {
        opacity:0;
/*        transform:  scaleX(7.00) scaleY(7.00) ;*/
    }
    50% {
        opacity:0;
/*        transform:  scaleX(7.01) scaleY(7.00) ;*/
    }
    100% {
        opacity:1;
/*        transform:  scaleX(1.00) scaleY(1.00) ;*/
    }
}

@-moz-keyframes animationFrames-subNav{
    0% {
        opacity:0;
/*        -moz-transform:  scaleX(7.00) scaleY(7.00) ;*/
    }
    50% {
        opacity:0;
/*        -moz-transform:  scaleX(7.01) scaleY(7.00) ;*/
    }
    100% {
        opacity:1;
/*        -moz-transform:  scaleX(1.00) scaleY(1.00) ;*/
    }
}

@-webkit-keyframes animationFrames-subNav {
    0% {
        opacity:0;
/*        -webkit-transform:  scaleX(7.00) scaleY(7.00) ;*/
    }
    50% {
        opacity:0;
/*        -webkit-transform:  scaleX(7.01) scaleY(7.00) ;*/
    }
    100% {
        opacity:1;
/*        -webkit-transform:  scaleX(1.00) scaleY(1.00) ;*/
    }
}

@-o-keyframes animationFrames-subNav {
    0% {
        opacity:0;
/*        -o-transform:  scaleX(7.00) scaleY(7.00) ;*/
    }
    50% {
        opacity:0;
/*        -o-transform:  scaleX(7.01) scaleY(7.00) ;*/
    }
    100% {
        opacity:1;
/*        -o-transform:  scaleX(1.00) scaleY(1.00) ;*/
    }
}

@-ms-keyframes animationFrames-subNav {
    0% {
        opacity:0;
/*        -ms-transform:  scaleX(7.00) scaleY(7.00) ;*/
    }
    50% {
        opacity:0;
/*        -ms-transform:  scaleX(7.01) scaleY(7.00) ;*/
    }
    100% {
        opacity:1;
/*        -ms-transform:  scaleX(1.00) scaleY(1.00) ;*/
    }
}