.module--quote{
    .quote{
        font-size:30px;
        text-align: center;
    }

}
@include media-breakpoint-down(sm) {
    .module--quote .quoted{
        text-align: center;
    }
}