@font-face {
    font-family:"Carnas-Regular";
    src:url("../fonts/Carnas-Reg.woff2") format("woff2"),url("../fonts/Carnas-Reg.woff") format("woff"),url("../fonts/Carnas-Reg.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"Carnas-Bold";
    src:url("../fonts/Carnas-Bold.woff2") format("woff2"),url("../fonts/Carnas-Bold.woff") format("woff"),url("../fonts/Carnas-Bold.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"Carnas-Light";
    src:url("../fonts/Carnas-Lig.woff2") format("woff2"),url("../fonts/Carnas-Lig.woff") format("woff"),url("../fonts/Carnas-Lig.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"Carnas-Medium";
    src:url("../fonts/Carnas-Med.woff2") format("woff2"),url("../fonts/Carnas-Med.woff") format("woff"),url("../fonts/Carnas-Med.otf") format("opentype");
    font-style:normal;font-weight:400;
}