.page5 .module--header-image .caption{
    padding-top:50px;
}
.module--header-image{
    .breadcrumbmenu{color: $white; text-align: right; position:absolute; left:0;}
    .inline-list li {
        margin-right: 0px;
    }
    
    .caption{
        top:50%;
        transform: translateY(-50%);
        left:0;
        position:absolute;
        color:$white;
        text-align: center;
        font-size: 17px;
        h3{color:$white; font-size: 50px;}
    }
    .row{position:relative;
    }
    
}
@include media-breakpoint-down(sm) {
    .module--header-image .breadcrumbmenu{
        padding:5px 30px!important;
    }
    .module--header-image .caption{
         padding:5px 20px;
        font-size: 12px;
        h3{color:$white; font-size: 20px; margin-bottom:10px;}
    }
/*    .page5 .module--header-image .caption{
    padding-top:40px;
    
}*/
}