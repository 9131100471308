.module--teaser .container .row{
    @include shadow;
    background-color: $white;
}
.module--teaser .container .col-12{
    /*    padding-left:45px;
        padding-right:45px;*/
}
.teaser h4{
    text-transform: uppercase;
    font-family: "Carnas-Medium";
}
.teaser{border-top:5px solid #ffffff; opacity: 0.75; @include transition(.5s);}
.teaser:hover{border-top:5px solid $main-blue; opacity: 1;}
.module--teaser{
    position: relative;
    margin-top: -15%;
    z-index: 1000;
}
@include media-breakpoint-down(sm) {
    .teaser{border-top:5px solid $main-blue; opacity: 1; @include transition(.5s);}
    .teaserHolder{@include shadow;  padding: 30px;}
    .module--teaser .container .row{
        box-shadow: none;
    }
    .module--teaser{
        position: relative;
        margin-top: 0px;
        z-index: 1000;
    }
    .module--teaser .container .row{
    background-color: transparent;
}
}
