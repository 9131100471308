.module--slidebox .slideboxDesktop .col-12{@include transition(1s);}
.module--slidebox .container.slideboxDesktop .row{flex-wrap: nowrap;}
.module--slidebox .slideboxDesktop .row .first-part{opacity: 1;}
.module--slidebox .slideboxDesktop .row.movedLeft .first-part{margin-left:-50%; opacity: 0;}
.slideboxDesktop .extra-art{position: absolute;}
.module--slidebox .slideboxDesktop .drop1{    top: 30px;
    left: -20px;}
.module--slidebox .slideboxDesktop .drop2{top:0; left:60%;}
.module--slidebox .slideboxDesktop .drop3{bottom:0;left:40%;}
.module--slidebox  #slideboxswitchback img{    height: 30px;}

.module--slidebox .slideboxMobile .container-fluid .col-12{padding:0px;}
.module--slidebox .slideboxMobile .slideboxContent{opacity: 0;}
.module--slidebox .slideboxMobile .slideboxContent.opacity1{opacity: 1;}
.slideboxDesktopTest .slick-list{
    overflow: visible;
}
@include media-breakpoint-down(md) {
.module--slidebox .container.slideboxDesktop .row{flex-wrap: wrap;}
.module--slidebox .slideboxDesktop .drop2 {
    top: -100%;
    left: 60%;
}
.module--slidebox .slideboxDesktop .drop1 {
    top: -70%;
    left: 0px;
}
.module--slidebox .slideboxDesktop .drop3 {
    top: -50%;
    left: 40%;
}
}
.module--slidebox .slideboxDesktop .drop1:hover {
/*    animation-duration: 400ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-name: wiggle;*/
  }
@keyframes wiggle {
  0% { transform: translate(2px, 2px); }
  10% { transform: translate(-2px, 0); }
  20% { transform: translate(2px, -2px); }
  30% { transform: translate(-2px, 0); }
  40% { transform: translate(2px, -2px); }
  50% { transform: translate(-2px, 0); }
  60% { transform: translate(2px, 2px); }
  70% { transform: translate(-2px, 2px); }
  80% { transform: translate(2px, -2px); }
  90% { transform: translate(-2px, 0); }
  100% { transform: translate(0, 0); }
}